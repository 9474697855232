<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <el-input :type="itemData.type" v-model="itemVal" v-bind="[itemData]" @input="input" @change="change">
      <el-select v-model="itemData.select.model" slot="prepend" @change="onselect">
        <el-option label="微信" value="微信" ></el-option>
        <el-option label="支付宝" value="支付宝"></el-option>
      </el-select>
    </el-input>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置数据
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: String
    },

    // 当前所在表单组配置项,用于事件函数的参数
    groupData: Object,

    // 表单配置项,用于事件函数的参数
    formData: Array,

    // 表单数据,用于事件函数的参数
    form: Object
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  methods: {
    // input方法触发父组件同步更新数据
    input(e) {
      this.$emit('input', this.itemVal)
    },
    onselect(val) {
      this.$parent.form[this.itemData.selectModel] = val
    },
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData)
      }
    }
  }
}
</script>
